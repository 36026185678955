/// <reference types="google.maps" />

import 'bootstrap-notify';
import { number } from '../formatters-ts';

export { showSuccess, showError, showAlert, showWarning, AlertType, AlertPosition, Alert } from '@rescover/ui-library';
export { default as Str } from './Str';
export { getBrowserLocales, getDefaultBrowserLocale } from './BrowserLocales';
export { default as startCountDown } from './CountDown';
export { HandleErrors } from './AxiosSettings';
export { storageAvailable } from './localStorage';
export { findMyLocation } from './GeoLocation';
export * from './Amortization';
export { isEmpty, escapeHtml } from './extensions';
export * from './time';
import * as Sentry from '@sentry/vue';

export const currentYear = new Date().getFullYear();
export const currentMonth = new Date().getMonth() + 1; // getMonth is index based
export const dateFormat = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
export const isoDateFormat = /^\d{4}-\d{2}-\d{2}$/;

export function hasBits(value: number | undefined, test: number): boolean {
   if (value == undefined) return false;
   return (BigInt(value) & BigInt(test)) == BigInt(test);
}

export function sortNumbers(a: number, b: number): number {
   if (a > b) {
      return 1;
   }
   if (a < b) {
      return -1;
   }
   return 0;
}

export function reportError(message: unknown) {
   if (isError(message))
      Sentry.captureException(message);
   else if (isText(message))
      Sentry.captureException(message);
}

export function isText(data: unknown): data is string {
   return typeof data === 'string';
}
export function isError(data: unknown): data is Error {
   return data instanceof Error;
}

export function waitTimeout(milliseconds: number): Promise<void> {
   // Wait x milliseconds, the resolve promise to continue
   return new Promise<void>((resolve) => {
      //Start timer
      setTimeout(() => {
         //Resolve... promise
         resolve();
      }, milliseconds);
   });
}

/**
 * Calculate Monthly Loan Payment
 * 
 * @param {number} interestRate Monthly interest rate
 * @param {number} months Loan term in months
 * @param {number} loanAmount Loan Amount
 * @param {number} futureValue Future Value
 * @param {number} type Amortization style. US=0; Canadian=1
 */
export function PMT(interestRate: number, months: number, loanAmount: number, futureValue: number = 0, type: number = 0): number {
   if (interestRate == 0)
      return (-(loanAmount + futureValue) / months);

   const pvif = Math.pow((1 + interestRate), months);
   let pmt = (interestRate * loanAmount * (pvif + futureValue) / (pvif - 1));

   if (type == 1)
      pmt /= (1 + interestRate);

   return pmt;
}

/**
 * Calculate amount of days between two dates
 * @param from Oldest date
 * @param to Newer date. Uses Date.now if nothing passed in parameter
 * @returns Number of days
 */
export function daysSince(from: Date, to?: Date): number {
   if (!from) return 0;
   // To calculate the no. of days between two dates
   return ((to ?? new Date()).getTime() - from.getTime()) / 86_400_000;
}

/**
 * Calculate amount of hours between two dates
 * @param from Oldest date
 * @param to Newer date. Uses Date.now if nothing passed in parameter
 * @returns Number of hours
 */
export function hoursSince(from: Date, to?: Date): number {
   if (!from) return 0;
   // To calculate the no. of hours between two dates
   return ((to ?? new Date()).getTime() - from.getTime()) / 3_600_000;
}

/**
 * Calculate amount of minutes between two dates
 * @param from Oldest date
 * @param to Newer date. Uses Date.now if nothing passed in parameter
 * @returns Number of minutes
 */
export function minutesSince(from: Date, to?: Date): number {
   // To calculate the no. of minutes between two dates
   return ((to ?? new Date()).getTime() - from.getTime()) / 60_000;
}

/**
 * Calculate amount of seconds between two dates
 * @param from Oldest date
 * @param to Newer date. Uses Date.now if nothing passed in parameter
 * @returns Number of seconds
 */
export function secondsSince(from: Date, to?: Date): number {
   // To calculate the no. of seconds between two dates
   return ((to ?? new Date()).getTime() - from.getTime()) / 1_000;
}

/**
 * Calculate amount of time between two dates, returns formated string
 * @param from Oldest date
 * @param to Newer date. Uses Date.now if nothing passed in parameter
 * @returns Formated timespan string
 */
export function timeSince(from: Date, to?: Date): string {
   if (!from) return '';
   const days = daysSince(from, to);
   if (days < 1) {
      const hours = hoursSince(from, to);
      if (hours < 1) {
         const minutes = minutesSince(from, to);
         if (minutes < 2)
            return '1 Minute';
         return `${number(minutes, 0)} Minutes`;
      }
      if (hours < 2)
         return '1 Hour';
      return `${number(hours, 0)} Hours`;
   }
   if (days < 2)
      return '1 Day';
   return `${number(days, 0)} Days`;
}


export function addDays(date: Date, days: number): Date {
   const result = new Date(date);
   result.setDate(result.getDate() + days);
   return result;
}

export function paginate<T>(array: Array<T>, page: number, pageSize: number): Array<T> {
   // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
   return array.slice((page - 1) * pageSize, page * pageSize);
}
